<script setup>

const props = defineProps({
  specialOrder: {
    type: Object,
    required: true,
  },
});

const navItems = (id) => {
  return [
    {
      text: "Details",
      icon: "mdi-text-box-outline",
      to: `/special-orders/${id}`,
    },
    {
      text: "Files",
      icon: "mdi-file-outline",
      to: `/special-orders/${id}/files`,
    },
    {
      text: "Billable Activity",
      icon: "mdi-receipt",
      to: `/special-orders/${id}/billable-activity`,
    },
    {
      text: "Parcel Shipment",
      icon: "mdi-package",
      to: `/special-orders/${id}/parcel-shipment`,
    },
  ];
};

</script>

<template>
  <v-card flat class="d-flex flex-wrap mx-4 border">
    <div class="pl-4 py-4 w-100 bg-surface border-b d-flex align-center">
      <a class="text-h7" href="/special-orders">Special Orders</a>
      <span class="px-2">/</span>
      <span class="text-h6">{{ specialOrder?.id }}
        - {{ specialOrder?.client?.nickname }} 
        - PO: {{ specialOrder?.po_number }} 
        - SOS: {{ specialOrder?.sos_number }}
      </span>
      <div class="ml-auto">
        <v-btn
          color="primary"
          variant="outlined"
        >
          Packing Slip
        </v-btn>

        <v-btn
          color="primary"
          class="mx-4"
          variant="outlined"
        >
          Packing Label
        </v-btn>
      </div>
    </div>
    <div class="w-100 d-flex">
      <div class="w-25 overflow-y-auto border-e-sm">

        <v-card flat class="mx-auto pa-2 w-100">
          <div class="text-center" v-if="!specialOrder">
            <v-progress-circular
              :width="8"
              :size="50"
              color="primary"
              class="mb-16"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list>
            <v-list-item
              exact
              v-if="specialOrder"
              v-for="(item, i) in navItems(specialOrder.id)"
              :key="i"
              :value="item"
              :to="item.to"
              color="primary"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
        <v-divider></v-divider>
      </div>
      <div class="w-75 overflow-y-auto">
        <slot />
      </div>
    </div>
  </v-card>
</template>

<style scoped>
@media (max-width: 768px) {
  .v-card .mx-auto {
    max-width: 75px;
  }
}
</style>
